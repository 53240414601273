
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'

export default defineComponent({
  components: {
    Grid,
    // CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'mapping2',
      title: 'Mapping_2',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canUpdate: false,
      canCreate: false,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'ProductNo', title: '品號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: 'Name',
          title: '品名',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        { field: 'CustomerNo', title: '客戶代號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CustomerSKU', title: '客戶SKU', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Specifications', title: '規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundUseName', title: '退稅用品名', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundUseSpecifications', title: '退稅用規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'SemiFinishedProduct', title: '半成品', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundName', title: '半成品退稅品名', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundSpecifications', title: '半成品半退稅規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Quantity', title: '用量', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'DocumentNo', title: '文號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('mapping2/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('mapping2/query') : undefined,
        save: model
          ? (params) => model.dispatch('mapping2/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'ProductNo',
          title: '品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Name',
          title: '品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CustomerNo',
          title: '客戶代號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CustomerSKU',
          title: '客戶SKU',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Specifications',
          title: '規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseName',
          title: '退稅用品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseSpecifications',
          title: '退稅用規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'SemiFinishedProduct',
          title: '半成品',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundName',
          title: '半成品退稅品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundSpecifications',
          title: '半成品半退稅規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Quantity',
          title: '用量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'DocumentNo',
          title: '文號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
      ],
      // rules: {
      //   Name: [{ type: 'string', required: true }]
      // }
    }

    return {
      grid,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
